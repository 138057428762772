import { add } from "date-fns";
import type { Payment, Refund } from "@prisma/client";

import { type AppLoadContext } from "~/types/remix";

import prisma from "../db.server";
import { paymentsClientFactory } from "~/app/shopify.server";
import type {
  RefundSessionResolveResponse,
  RefundSessionRejectResponse,
  PaymentSessionResolveResponse,
  PaymentSessionPendingResponse,
  PaymentsAppConfigureMutationResponse,
} from "~/app/paymentsApiClient";

export async function resolvePayment(
  context: AppLoadContext,
  payment: Payment,
) {
  const session = await prisma.session.findFirst({
    where: {
      shop: `${payment?.shopName}.myshopify.com`,
      expires: null,
    },
  });

  if (!session || !session.accessToken || !session.shop) {
    context?.logger?.error(`No session for shop ${payment?.shopName}`);
    return;
  }

  const paymentsClient = paymentsClientFactory.createClient({
    shop: session.shop,
    accessToken: session.accessToken,
  });

  const response = await paymentsClient.query<PaymentSessionResolveResponse>({
    data: {
      query: `#graphql
        mutation paymentSessionResolve($id: ID!) {
          paymentSessionResolve(id: $id) {
            paymentSession {
              id
            }
            userErrors {
              field
              message
            }
          }
        }`,
      variables: {
        id: payment?.shopifyId as string,
      },
    },
  });

  const responseJson = response.body;

  if (
    responseJson &&
    responseJson.data.paymentSessionResolve.userErrors.length > 0
  ) {
    context?.logger?.error(
      `Error modifying payment session ${payment?.shopifyId}`,
    );
  }
}

export async function pendingPayment(
  context: AppLoadContext,
  payment: Payment,
) {
  // TODO check 14 days expiry config for prod app
  const expiryDate = add(new Date(), { days: 7 });
  const session = await prisma.session.findFirst({
    where: {
      shop: `${payment.shopName}.myshopify.com`,
      expires: null,
    },
  });

  if (!session || !session.accessToken || !session.shop) {
    console.log(`No session for shop ${payment.shopName}`);
    context?.logger?.error(`No session for shop ${payment.shopName}`);
    return;
  }

  const paymentsClient = paymentsClientFactory.createClient({
    shop: session.shop,
    accessToken: session.accessToken,
  });

  const response = await paymentsClient.query<PaymentSessionPendingResponse>({
    data: {
      query: `#graphql
      mutation paymentSessionPending($id: ID!, $pendingExpiresAt: DateTime!, $reason: PaymentSessionStatePendingReason!) {
        paymentSessionPending(id: $id, pendingExpiresAt: $pendingExpiresAt, reason: $reason) {
          paymentSession {
            id
            state {
              ... on PaymentSessionStatePending {
                reason
              }
            }
            nextAction {
              action
              context {
                ... on PaymentSessionActionsRedirect {
                  redirectUrl
                }
              }
            }
          }
          userErrors {
            field
            message
          }
        }
      }`,
      variables: {
        id: payment.shopifyId as string,
        pendingExpiresAt: expiryDate.toISOString(),
        reason: "PARTNER_ACTION_REQUIRED",
      },
    },
  });

  const responseJson = response.body;

  if (
    responseJson &&
    responseJson.data.paymentSessionPending.userErrors.length > 0
  ) {
    context?.logger?.error(
      `Error modifying payment session ${payment.shopifyId}`,
    );
    console.log(`Error modifying payment session ${payment.shopifyId}`);
    console.log(responseJson.data.paymentSessionPending.userErrors);
  }

  const redirectUrl =
    responseJson.data.paymentSessionPending.paymentSession.nextAction.context
      .redirectUrl;

  return redirectUrl;
}

export async function resolveRefund(context: AppLoadContext, refund: Refund) {
  const session = await prisma.session.findFirst({
    where: {
      shop: `${refund.shopName}.myshopify.com`,
      expires: null,
    },
  });

  if (!session || !session.accessToken || !session.shop) {
    context?.logger?.error(`No session for shop ${refund.shopName}`);
    return;
  }

  const paymentsClient = paymentsClientFactory.createClient({
    shop: session.shop,
    accessToken: session.accessToken,
  });

  const response = await paymentsClient.query<RefundSessionResolveResponse>({
    data: {
      query: `#graphql
        mutation RefundSessionResolve($id: ID!) {
          refundSessionResolve(id: $id) {
            refundSession {
              id
              state
            }
            userErrors {
              field
              message
            }
          }
        }`,
      variables: {
        id: refund.shopifyId as string,
      },
    },
  });

  const responseJson = response.body;

  if (
    responseJson &&
    responseJson.data.refundSessionResolve.userErrors.length > 0
  ) {
    context?.logger?.error(
      `Error modifying refund session ${refund.shopifyId}`,
    );
    return;
  }

  await prisma.refund.update({
    where: { id: refund.id },
    data: {
      status: "RESOLVED",
    },
  });
}

export async function rejectRefund(
  context: AppLoadContext,
  refund: Refund,
  rejectReason: string,
) {
  const session = await prisma.session.findFirst({
    where: {
      shop: `${refund.shopName}.myshopify.com`,
      expires: null,
    },
  });

  if (!session || !session.accessToken || !session.shop) {
    context?.logger?.error(`No session for shop ${refund.shopName}`);
    return;
  }

  const paymentsClient = paymentsClientFactory.createClient({
    shop: session.shop,
    accessToken: session.accessToken,
  });

  const response = await paymentsClient.query<RefundSessionRejectResponse>({
    data: {
      query: `#graphql
        mutation RefundSessionReject($id: ID!, $reason: RefundSessionRejectionReasonInput!) {
          refundSessionReject(id: $id, reason: $reason) {
            refundSession {
              id
              state
            }
            userErrors {
              field
              message
            }
          }
        }`,
      variables: {
        id: refund.shopifyId as string,
        reason: {
          code: "PROCESSING_ERROR",
          merchantMessage: rejectReason,
        },
      },
    },
  });

  const responseJson = response.body;
  console.log(responseJson);

  if (
    responseJson &&
    responseJson.data.refundSessionReject.userErrors.length > 0
  ) {
    context?.logger?.error(
      `Error modifying refund session ${refund.shopifyId}`,
    );
    return;
  }

  await prisma.refund.update({
    where: { id: refund.id },
    data: {
      status: "REJECTED",
    },
  });
}

export async function completeOnboarding(session: any) {
  const paymentsClient = paymentsClientFactory.createClient(session);

  const response =
    await paymentsClient.query<PaymentsAppConfigureMutationResponse>({
      data: {
        query: `#graphql
        mutation paymentsAppConfigure($ready: Boolean!, $externalHandle: String) {
          paymentsAppConfigure(ready: $ready, externalHandle: $externalHandle) {
            userErrors {
              field
              message
            }
          }
        }`,
        variables: {
          ready: true,
          externalHandle: "test",
        },
      },
    });

  const responseJson = response.body;

  if (!responseJson) {
    throw new Error("No response from paymentsAppConfigure");
  }

  if (responseJson.data.paymentsAppConfigure.userErrors.length > 0) {
    throw new Error(
      JSON.stringify(responseJson.data.paymentsAppConfigure.userErrors),
    );
  }

  return responseJson;
}
